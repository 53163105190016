import { navigate } from 'gatsby';
import * as React from 'react';


const IndexPage = () => {
  React.useEffect(() => {
    navigate('/tools/home-value-estimator/calculate/');
  }, []);
  return (
    <div>
      HI
    </div>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage;
